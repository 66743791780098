@media only screen and (max-width: 768px) {
  body {
    height: auto;
    overflow: scroll !important;
    min-height: unset !important;
  }
  #root {
    height: 100%;
    width: 100%;
  }
  .container {
    display: flex !important;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  .App {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
  }

  .react-viewer-close {
    top: 57px !important;
    right: 0 !important;
    overflow: visible !important;
    z-index: 99999 !important;
  }
}
