body {
  background-color: #e3e5eb;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
#root {
  height: 100%;
  width: 100%;
}
.container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 100%;
  grid-template-areas: 'app';
  grid-template-columns: 100%;
}
.App {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  grid-area: app;
}
